import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/UI/Layout"
import Navbar from "../../components/UI/Navbar"
import Seo from "../../components/Seo"
import ServicePage from "../../components/templates/ServicePage"
import KoiPondVideo from "../../assets/images/KoiFishInPondAndreattaWaterscapesCentralPointOregon.mp4"

const query = graphql`
  {
    allContentfulInstallationImages(
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: "koiPonds" } } } }
      }
    ) {
      nodes {
        id
        image {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
          id
          title
        }
      }
    }
  }
`

export default function KoiPondsPage() {
  const title = `Dedicated Koi Pond` // Singular (not plural)
  const {
    allContentfulInstallationImages: { nodes: image },
  } = useStaticQuery(query)

  const imageList = image[0].image

  return (
    <Layout>
      <Navbar />
      <Seo
        title={`${title}s`}
        description={`Andreatta Waterscapes builds ${title}s in Medford, Grants Pass, Ashland Oregon, and nearby. See what we can build for you.`}
      />

      <ServicePage
        imageList={imageList}
        title={title}
        video={true}
        img={
          <video
            src={KoiPondVideo}
            muted
            loop
            autoPlay
            playsInline
            style={{
              zIndex: -1,
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: "cover",
              opacity: 1,
            }}
            />
          // <StaticImage
          //   src="../../assets/images/Koi Fish in Pond_Andreatta Waterscapes_Central Point Oregon.jpg"
          //   quality={90}
          //   loading="eager"
          //   style={{
          //     position: "absolute",
          //     width: "100%",
          //     zIndex: 1,
          //     height: "21rem",
          //   }}
          //   objectFit="cover"
          //   objectPosition="50% 50%"
          //   alt="Koi Pond Central Point Oregon"
          // />
        }
        tagline={`Koi fish bring beauty and joy`}
        body={[
          {
            id: 0,
            type: "paragraph",
            content: `Our customers love their koi! They are beautiful fish that add color, movement, and life to your pond. Whether you desire to keep koi as a casual interest or a serious hobby, having a dedicated koi pond comes with some special considerations.`,
          },
          {
            id: 1,
            type: "paragraph",
            content: `While goldfish can live in a simple ecosystem pond, koi have a few more requirements such as the size of the pond and added filtering. Koi fish are beautiful and can live many years in a backyard pond when provided with a healthy environment.`,
          },
          {
            id: 2,
            type: "paragraph",
            content: `We provide a hybrid approach to building koi ponds that keeps the traditional beauty of a water garden but brings in the more technical aspects of upgraded filtering, size, and depth.`,
          },
        ]}
      />
    </Layout>
  )
}
